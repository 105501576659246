export function header_footer() {
  
  const spBtn = () => {
    const spMenuBtn = $('#js-sp-menu'),
        spMenuLink = $('.header__menu-link'),
        spMenu = $('.header__nav'),
        logoMenuSp = $('.header__logo-link-sp'),
        wHeight = $(window).height(),
        wScroll = $(window).scrollTop(),
        mailIcon = $('.header__sp-link-contact');
        spMenuBtn.on('click', function(){
          const nowClose = $(this).hasClass('is-close');
          const nowOpen = $(this).hasClass('is-open');       
          if(nowClose){
              $('html').addClass('is-view-lock');
              $(this).removeClass('is-close');
              $(this).children().removeClass('is-close');
              $(this).addClass('is-open');
              $(this).children().addClass('is-open');
              spMenu.fadeIn().css('display','flex');
              logoMenuSp.addClass('is-appear');
              mailIcon.addClass('is-appear');
          } else if(nowOpen){
              $('html').removeClass('is-view-lock');
              $(this).removeClass('is-open');
              $(this).children().removeClass('is-open');
              $(this).addClass('is-close');
              $(this).children().addClass('is-close');
              spMenu.fadeOut();
              logoMenuSp.removeClass('is-appear');
              mailIcon.removeClass('is-appear');                
          }
      });
    spMenuLink.on('click', function(){
      spMenuBtn.trigger('click');
    })
  }

  
  $(()=>{
    spBtn();
  })


};


