import anime from 'animejs/lib/anime.es.js';

export function blobAnimation() {
  let blobShapes = [
    /*{
      d: 'M51.7,-69.4C67.5,-59.7,81.2,-45.3,87.5,-28C93.7,-10.7,92.3,9.4,86.2,28C80.1,46.5,69.2,63.4,54,73.9C38.9,84.4,19.4,88.6,0.7,87.7C-18,86.7,-36,80.5,-51.8,70.2C-67.6,59.9,-81.1,45.3,-86.9,28.1C-92.8,10.8,-90.9,-9.1,-84.8,-27.4C-78.7,-45.8,-68.2,-62.6,-53.4,-72.7C-38.5,-82.8,-19.3,-86.1,-0.7,-85.2C17.9,-84.3,35.9,-79.2,51.7,-69.4Z',
    },
    {
      d: 'M51.3,-70.5C65.6,-60.3,75.6,-44,81.6,-26.3C87.6,-8.6,89.7,10.6,85,28.4C80.3,46.2,68.8,62.5,53.5,74.1C38.2,85.7,19.1,92.5,-0.2,92.8C-19.5,93.1,-39.1,86.9,-54.6,75.4C-70.2,63.9,-81.7,47.1,-86.4,29C-91.2,10.8,-89,-8.7,-82.3,-25.9C-75.6,-43.1,-64.3,-58,-49.8,-68.2C-35.3,-78.4,-17.7,-83.9,0.4,-84.5C18.5,-85.2,37,-80.8,51.3,-70.5Z',
    },
    {
      d: 'M50.4,-68.2C65.6,-58.3,78.4,-44,84,-27.2C89.6,-10.4,88,8.9,81.9,26.2C75.7,43.6,65,59.1,50.6,69.2C36.2,79.3,18.1,84,-0.5,84.7C-19.2,85.5,-38.4,82.3,-53.1,72.3C-67.8,62.3,-78.1,45.5,-82.7,27.7C-87.3,9.9,-86.2,-8.9,-81.1,-26.8C-75.9,-44.8,-66.7,-61.9,-52.5,-72.1C-38.3,-82.3,-19.2,-85.5,-0.8,-84.5C17.6,-83.4,35.2,-78,50.4,-68.2Z',
    },
    {
      d: 'M53.3,-72.4C68.4,-62.4,79.4,-45.9,85,-27.8C90.6,-9.8,90.7,9.9,85.1,27.9C79.6,45.9,68.4,62.4,53.3,73C38.2,83.7,19.1,88.6,0,88.7C-19.1,88.7,-38.3,83.9,-53.1,73.1C-68,62.3,-78.7,45.7,-83.6,27.8C-88.5,9.9,-87.7,-9.2,-81.4,-25.9C-75.2,-42.6,-63.4,-56.9,-49,-67.1C-34.5,-77.4,-17.2,-83.6,0.9,-84.9C19.1,-86.2,38.2,-82.5,53.3,-72.4Z',
    },
    {
      d: 'M52.5,-70.5C68.2,-60.8,81.3,-45.7,87.3,-28.1C93.4,-10.5,92.4,9.6,86,27.6C79.7,45.6,68,61.5,52.7,71.7C37.5,81.8,18.8,86.2,-0.3,86.6C-19.3,86.9,-38.6,83.3,-54.2,73.3C-69.7,63.3,-81.6,46.8,-87.2,28.5C-92.9,10.3,-92.3,-9.8,-86.3,-27.9C-80.2,-46,-68.5,-62.1,-53.2,-72C-37.9,-81.9,-19,-85.4,-0.3,-85C18.4,-84.6,36.7,-80.2,52.5,-70.5Z',
    },
    {
      d: 'M50.4,-69.3C65.2,-58.5,77.1,-43.7,83.5,-26.6C89.8,-9.4,90.6,10.1,84.9,27.6C79.3,45.1,67.2,60.5,52,70.8C36.8,81,18.4,86,-0.4,86.6C-19.2,87.2,-38.5,83.3,-53.2,72.9C-68,62.5,-78.2,45.6,-83.5,27.6C-88.8,9.5,-89.1,-9.8,-84.1,-27.9C-79.1,-46.1,-68.6,-63.1,-53.7,-73.8C-38.8,-84.5,-19.4,-88.9,-0.8,-87.8C17.8,-86.6,35.5,-80,50.4,-69.3Z',
    },*/
    {
      d: 'M39.6,-40.7C49.4,-29.7,54.3,-14.9,56.9,2.5C59.4,20,59.6,39.9,49.8,53.7C39.9,67.6,20,75.3,3.8,71.5C-12.4,67.8,-24.9,52.5,-34.6,38.7C-44.4,24.9,-51.4,12.4,-55.9,-4.4C-60.3,-21.3,-62,-42.5,-52.3,-53.4C-42.5,-64.4,-21.3,-65,-3.2,-61.8C14.9,-58.6,29.7,-51.6,39.6,-40.7Z',
    },
    {
      d: 'M41.6,-47.9C51.5,-31.8,55.4,-15.9,57,1.6C58.6,19.1,58,38.2,48.1,53.5C38.2,68.8,19.1,80.1,1.7,78.4C-15.7,76.7,-31.4,61.9,-44.8,46.7C-58.2,31.4,-69.3,15.7,-71.4,-2C-73.4,-19.8,-66.3,-39.5,-52.9,-55.7C-39.5,-71.9,-19.8,-84.4,-1.9,-82.5C15.9,-80.5,31.8,-64.1,41.6,-47.9Z',
    },
    {
      d: 'M47.3,-46.8C57.7,-36.9,60.2,-18.4,59.7,-0.5C59.2,17.5,55.8,34.9,45.3,47.1C34.9,59.3,17.5,66.2,0,66.2C-17.4,66.2,-34.9,59.2,-45.8,47C-56.8,34.9,-61.4,17.4,-60.5,0.8C-59.7,-15.7,-53.4,-31.5,-42.5,-41.4C-31.5,-51.3,-15.7,-55.3,1.3,-56.7C18.4,-58,36.9,-56.6,47.3,-46.8Z',
    },
    {
      d: 'M42.7,-36.5C58.2,-27.1,75.9,-13.5,76.9,1C77.9,15.6,62.3,31.1,46.7,39.9C31.1,48.7,15.6,50.7,-0.1,50.7C-15.7,50.8,-31.4,48.9,-45.7,40.1C-60.1,31.4,-73.1,15.7,-74.3,-1.2C-75.4,-18,-64.7,-36,-50.4,-45.4C-36,-54.8,-18,-55.6,-2.2,-53.4C13.5,-51.1,27.1,-45.9,42.7,-36.5Z',
    },
    {
      d: 'M51.1,-56.8C60.4,-41.8,58,-20.9,55,-3C52.1,14.9,48.4,29.9,39.2,41C29.9,52.1,14.9,59.4,-0.8,60.3C-16.6,61.1,-33.2,55.4,-43.3,44.3C-53.4,33.2,-57,16.6,-58.2,-1.1C-59.3,-18.8,-57.9,-37.7,-47.8,-52.6C-37.7,-67.6,-18.8,-78.7,1,-79.8C20.9,-80.8,41.8,-71.8,51.1,-56.8Z',
    },
    {
      d: 'M40.9,-40C53.1,-28.7,63.1,-14.3,67.3,4.2C71.5,22.7,69.8,45.4,57.6,57.3C45.4,69.2,22.7,70.2,4.7,65.6C-13.4,60.9,-26.7,50.5,-39.9,38.6C-53,26.7,-65.9,13.4,-69.9,-4C-73.9,-21.4,-69,-42.7,-55.9,-54C-42.7,-65.4,-21.4,-66.6,-3.5,-63.1C14.3,-59.6,28.7,-51.3,40.9,-40Z',
    },
    {
      d: 'M49.6,-50.7C62,-37.1,68.3,-18.6,70,1.7C71.6,21.9,68.7,43.9,56.3,58.3C43.9,72.7,21.9,79.6,4.4,75.2C-13.2,70.8,-26.3,55.1,-42.6,40.7C-59,26.3,-78.5,13.2,-78.9,-0.4C-79.3,-14,-60.6,-27.9,-44.2,-41.4C-27.9,-55,-14,-68.1,2.3,-70.4C18.6,-72.7,37.1,-64.2,49.6,-50.7Z',
    },
    {
      d: '"M46.2,-46.8C56.6,-35.8,59.5,-17.9,59.6,0.1C59.7,18.1,57,36.2,46.6,48.1C36.2,59.9,18.1,65.4,-2.7,68.1C-23.4,70.7,-46.9,70.5,-58,58.7C-69.1,46.9,-67.9,23.4,-63.3,4.6C-58.7,-14.3,-50.8,-28.6,-39.7,-39.7C-28.6,-50.7,-14.3,-58.5,1.8,-60.3C17.9,-62.1,35.8,-57.9,46.2,-46.8Z',
    },
  ];

  let blobMorph = anime({
    targets: '.c-blob',
    d: [{ value: [blobShapes[0].d, blobShapes[1].d] }, { value: blobShapes[2].d }, { value: blobShapes[3].d }, { value: blobShapes[4].d }, { value: blobShapes[5].d },{ value: blobShapes[6].d }, { value: blobShapes[0].d }],
    duration: 20000,
    autoplay: true,
    easing: 'easeInOutSine',
    loop: true,
  });
}
