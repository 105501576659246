export function contents() {

    let modal = () =>{
        let movThumb = $('.js-movie-thumb'),
            vimeo = $('.modal-vimeo__inner');
            
        for(let num = 1;num <= movThumb.length;num++) {
            $('.js-movie-thumb-' + num).on('click', ()=>{
                $('.movie-modal-' + num).fadeIn();
            });
        }
        $('.modal .modal-bg,.modal .modal-close').on('click', function(){
            $('.modal').fadeOut();
            $('html').removeClass('modalset');
            if(vimeo.length){
                let player = new Vimeo.Player(vimeo);
                player.pause();
            }
        });
        $(document).on('click', '.modal .modal-bg,.modal .modal-close', function(){
            $('button.vjs-play-control.vjs-playing').trigger('click');
            videoControl("pauseVideo");
            function videoControl(action){
            let youtubeCls = $('.modal-youtube__inner');
            for(let i = 0;i < youtubeCls.length;i++){
                let $playerWindow = $('.modal-youtube__inner')[i].contentWindow;
                $playerWindow.postMessage('{"event":"command","func":"'+action+'","args":""}', '*');
            }
            }            
        })        
    }

    let atcHeadingImg = () => {
        let headingSlids = $('.atc-heading-slids');
        headingSlids.slick({
            arrows: false,
            swipe: false,
            autoplay: true,
            fade: true,
            pauseOnFocus: false,
            pauseOnHover: false,
            pauseOnDotsHover: false,
            infinite: true,
            speed: 1000,
            autoplaySpeed: 5000,
            dots: true,
            appendDots: $('.atc-slids-dots-wrap'),
            dotsClass: 'slick-dots my-dotsatc-slids-dots',
        });
    }

    let atc = () => {
        $('.atc__ttl').matchHeight();
        $('.atc__intro').matchHeight();
    }

    let manga = () => {
        $('.atc-manga-btn').on('click',function(){
            $(this).prev('.atc-manga').addClass('is-open');
            $(this).remove();            
        })
    }

    let appearEff = () => {
        let vanishEl = $('.is-vanished');
        let appear = 'is-appear';
        
        vanishEl.each(function(){
            let vanishElOffset = $(this).offset().top;
            let scrollPos = $(window).scrollTop();
            let wh = $(window).height();

            if(scrollPos > vanishElOffset - wh + 200 ){
            $(this).addClass(appear);
            }
        });
    }

    let smoothScroll = () => {
        $('a[href^="#"]').on('click', function() {
            let adjust;
            let pc = window.matchMedia("(min-width: 1025px)").matches;
            let tb = window.matchMedia("(min-width: 768px) and (max-width: 1024px)").matches;
            let sp = window.matchMedia("(max-width: 767px)").matches;
            if(pc) {
                adjust = 90;
                console.log('pc');
            }else if(tb) {
                adjust = 80;
                console.log('tb');
            } else if(sp) {
                adjust = 60;
                console.log('sp');
            };    
            let speed = 400;
            let href= $(this).attr("href");
            let target = $(href == "#" || href == "" ? 'html' : href);
            let position = target.offset().top - adjust;
            $('body,html').animate({scrollTop:position}, speed, 'swing');
            return false;
        });
    }

    
    $(()=>{
        modal();
        atcHeadingImg();
        smoothScroll();
        manga();
    })

    $(window).on('load scroll', function(){
        appearEff();
    });

    $(window).on('load resize', function(){
        atc();
        
    });

};


